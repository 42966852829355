import { api } from "../providers/AuthProvider";
import format from "date-fns/format";
const GetPhaseDatas = async (id, date) => {
  return api
    .get(`/api/pv_phase_data/${id}`, {
      params: { date: format(date, "yyyy-MM-dd") },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetPhaseDatas;
