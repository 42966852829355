import { useEffect, useState } from "react";
import GetCurrent from "../api/GetCurrent";
import { Box, Typography, LinearProgress } from "@mui/material";
import DayPicker from "./DayPicker";
import GetVoltage from "../api/GetVoltage";
import StringsChart from "./StringsChart";
const StringWrapper = ({ pickedInv, chartType, mpptAmount }) => {
  const [pickedDate, setPickedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [strings, setStrings] = useState();
  const [series, setSeries] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(series);
  }, [series]);
  const handleChangeDate = (val) => {
    setPickedDate(val);
  };
  const handleGetCurrents = async () => {
    setLoading(true);
    try {
      let response = await GetCurrent(pickedInv.id, pickedDate);
      const slicedArray = response.data.slice(0, mpptAmount);
      let temp = slicedArray.map((e, i) => {
        return { ...e, hidden: false };
      });
      setSeries(temp);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleGetVoltages = async () => {
    console.log("voltage");
    setLoading(true);
    try {
      let response = await GetVoltage(pickedInv.id, pickedDate);
      console.log(response);
      if (response) {
        const slicedArray = response.data.slice(0, mpptAmount);
        let temp = slicedArray.map((e, i) => {
          return { ...e, hidden: false };
        });
        setSeries(temp);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (chartType === "current") {
      handleGetCurrents();
    } else {
      if (chartType === "voltage") {
        handleGetVoltages();
      }
    }
  }, [pickedInv, pickedDate]);

  return (
    <Box
      sx={{
        height: "18rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "normal" }}>
          {chartType === "voltage" ? "MPPT voltage" : "MPPT current"}
        </Typography>
        {loading && <LinearProgress />}
        <DayPicker handleChangeDate={handleChangeDate} />
      </Box>
      {series && series.length > 0 && !loading && (
        <StringsChart
          chartType={chartType}
          pickedDate={pickedDate}
          strings={strings}
          series={series}
        />
      )}
    </Box>
  );
};

export default StringWrapper;
