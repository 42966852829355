import { useEffect, useState } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import DayPicker from "./DayPicker";
import GetPVEnergy from "../api/GetEnergyData";
import BarChartBase from "./BarChartBase";
import ToggleAggr from "./ToggleAggr";
const EnergyChartWrapper = () => {
  const theme = useTheme();
  const [energyData, setEnergyData] = useState(null);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [aggr, setAggr] = useState("day");
  const handleChangeAggr = (e, val) => {
    setAggr(val);
  };
  const handleChangeDate = (val) => {
    setPickedDate(val);
  };
  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await GetPVEnergy(4, pickedDate, aggr);
      if (response) {
        setEnergyData(response.data.dataset);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, [pickedDate, aggr]);
  return (
    <Box
      sx={{
        height: "25rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "normal" }}>
          Daily generation
        </Typography>
        <DayPicker handleChangeDate={handleChangeDate} />
      </Box>
      <Box sx={{ alignSelf: "flex-end" }}>
        <ToggleAggr aggr={aggr} handleChangeAggr={handleChangeAggr} />
      </Box>
      {loading && <LinearProgress />}
      {energyData && energyData.length > 0 && (
        <Box sx={{ height: "20rem" }}>
          <BarChartBase
            color="pv"
            energyData={energyData}
            type="single"
            aggr={aggr}
          />
        </Box>
      )}
    </Box>
  );
};

export default EnergyChartWrapper;
