import { api } from "../providers/AuthProvider";
const GetPowerData = async (date, id) => {
  return api
    .get(`/api/pv_power_data/${id}`, {
      params: {
        date: date,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetPowerData;
