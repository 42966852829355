import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../providers/AuthProvider";
import styled from "@emotion/styled";
import logo from "../utils/logo-color.png";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import HttpsIcon from "@mui/icons-material/Https";
const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiInputAdornment-root": {
    color: theme.palette.text.primary,
  },
  "& .MuiInput-input": {
    color: "black",
  },
  "& .MuiFormLabel-root ": {
    color: "black",
  },
  "& .MuiInputBase-input": {
    color: "rgba(0,0,0,0.6)",
  },

  "& label.Mui-focused": {
    color: theme.palette.text.primary,
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.palette.text.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.text.primary,
  },
}));
const Login = () => {
  const theme = useTheme();

  const { login, error, loading } = useContext(AuthContext);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [authFailCode, setAuthFailCode] = useState(0);
  const handleLogin = async () => {
    setAuthFailCode(0);
    login(username, password);
  };

  const handleEnterPressed = (event) => {
    if (event.keyCode === 13) {
      // enter keyCode
      handleLogin();
    }
  };
  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.secondary,
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.primary.background,
          display: "flex",
          flexDirection: "column",
          alignItems: "space-evenly",
          gap: 6,
          padding: "3rem",
          borderRadius: "15px",
        }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={logo} />
        </Box>
        <StyledTextField
          type="text"
          id="standard-basic-1"
          required={true}
          label="Email"
          value={username}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <AlternateEmailIcon />
              </InputAdornment>
            ),
          }}
          onKeyDown={handleEnterPressed}
          onChange={(e) => setUserName(e.target.value)}
        />
        <StyledTextField
          type="password"
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <HttpsIcon />
              </InputAdornment>
            ),
          }}
          id="standard-basic-1"
          required={true}
          label="Hasło"
          value={password}
          onKeyDown={handleEnterPressed}
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <FormControlLabel
          sx={{ alignSelf: "center" }}
          control={
            <CheckBox
              sx={{ color: "white" }}
              checked={remember}
              onChange={handleRememberChange}
            />
          }
          label={
            <Typography
              variant="p"
              sx={{
                color: "#fff",
              }}
            >
              Zapamiętaj mnie na 14 dni
            </Typography>
          }
        /> */}

        <Button type="submit">Zaloguj</Button>
        {authFailCode !== 0 && (
          <Typography
            variant="p"
            sx={{ maxWidth: "10rem", color: theme.palette.primary.alert }}
          >
            {authFailCode}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Login;
