import { api } from "../providers/AuthProvider";
const GetInvertersTable = async (id) => {
  return api
    .get(`/api/inverters_table/${id}`)

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetInvertersTable;
