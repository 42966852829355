import { Grid } from "@mui/material";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router";
import { useTheme } from "@emotion/react";
const Wrapper = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        backgroundColor: theme.palette.primary.background,
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <Grid item xs={6} md={4} lg={2}>
        <Navbar />
      </Grid>
      <Grid item xs={6} md={8} lg={10} sx={{ height: "100%" }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Wrapper;
