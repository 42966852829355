import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import format from "date-fns/format";
import { useTheme } from "@emotion/react";
import { Box, Divider, Typography } from "@mui/material";

const CustomTooltip = ({ active, payload, label, theme }) => {
  if (active && payload && payload.length) {
    //
    //
    return (
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          border: "solid",
          borderColor: theme.palette.primary.background,
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">Time</Typography>
          <Typography variant="p">
            {format(new Date(label), "EEEE, HH:mm")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {payload.map((entry, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="p">{entry.value} V</Typography>
                </Box>
                <Divider />
              </>
            );
          })}
        </Box>
      </Box>
    );
  }

  return null;
};
const PhaseChart = ({ data }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="x"
          type="category"
          tickFormatter={(tick) => format(new Date(tick), "HH:mm")}
          minTickGap={20}
          allowDuplicatedCategory={false}
          // label={{
          //   value: "Godzina",
          //   angle: 0,
          //   fontSize: "15",
          //   offset: "-15",
          //   position: "insideBottomLeft",
          // }}
        />
        <YAxis
          type="number"
          domain={["auto", "auto"]}
          label={{
            value: "[V]",
            position: "insideTopLeft",
            offset: "-15",
          }}
        />
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={true}
          vertical={false}
        />
        <Tooltip
          content={<CustomTooltip theme={theme} />}
          cursor={{
            stroke: theme.palette.primary.background,
            strokeDasharray: 5,
          }}
        />

        <Line
          type="monotone"
          dataKey="a_voltage"
          name="L1"
          activeDot={{ r: 8 }}
          stroke={theme.palette.primary.blue}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default PhaseChart;
