import { api } from "../providers/AuthProvider";
import format from "date-fns/format";
const GetVoltage = async (id, date) => {
  return api
    .get(`/api/voltage_chart/${id}`, {
      params: {
        date: format(date, "yyyy-MM-dd"),
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetVoltage;
