import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import HouseIcon from "@mui/icons-material/House";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import LineTo from "react-lineto";
import GridIconEditable from "../utils/GridIconEditable";
import BatteryIconEditable from "../utils/BatteryIconEditable";
import { useState, useEffect } from "react";
const ConnectorsGraph = () => {
  const [data, setData] = useState({ grid: 0, pv: 0 });

  useEffect(() => {
    const min = 0.3;
    const max = 5;
    const interval = setInterval(
      () =>
        setData({
          pv: parseInt(Math.random() * (max - min) + min) * 0.97,
          grid: parseInt(Math.random() * (max + 2 - min + 1) + min + 1) * 0.97,
        }),
      4000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h2" sx={{ fontWeight: "normal" }}>
        Real-time overview
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // width: "100%",
          padding: "5%",
        }}
      >
        {" "}
        <div
          className="A"
          style={{
            // padding: "0.5rem",
            width: "7rem",
            height: "8rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <HouseIcon
            sx={{ fontSize: "4rem", color: theme.palette.primary.main }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "4rem",
              backgroundColor: "white",
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Typography variant="p" sx={{ color: theme.palette.primary.main }}>
              Consumption
            </Typography>
            <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
              {(data.grid + data.pv).toFixed(2)}{" "}
              <span style={{ fontSize: "1rem" }}>kW</span>
            </Typography>
          </Box>
        </div>
        <div
          className="B"
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            // padding: "0.5rem",
            width: "7rem",
            height: "8rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SolarPowerIcon
            sx={{ fontSize: "4rem", color: theme.palette.primary.green }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              height: "4rem",
              backgroundColor: "white",
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Typography variant="p" sx={{ color: theme.palette.primary.green }}>
              Production
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: theme.palette.primary.green }}
            >
              {data.pv} <span style={{ fontSize: "1rem" }}>kW</span>
            </Typography>
          </Box>
        </div>
      </Box>
      <LineTo
        from="A"
        delay="1000"
        fromAnchor="right"
        borderWidth={2}
        borderColor={theme.palette.primary.green}
        to="B"
        toAnchor="left"
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // width: "100%",
          padding: "5%",
        }}
      >
        <div
          className="C"
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            // padding: "0.5rem",
            width: "7rem",
            height: "8rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <GridIconEditable height="3rem" color={theme.palette.primary.red} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "4rem",
              backgroundColor: "white",
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Typography variant="p" sx={{ color: theme.palette.primary.red }}>
              Inflow
            </Typography>
            <Typography variant="h2" sx={{ color: theme.palette.primary.red }}>
              {data.grid} <span style={{ fontSize: "1rem" }}>kW</span>
            </Typography>
          </Box>
        </div>
        <div
          className="D"
          style={{
            backgroundColor: "white",
            borderRadius: 5,
            // padding: "0.5rem",
            width: "7rem",
            height: "8rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <BatteryIconEditable
              height="3rem"
              width="80"
              color={theme.palette.primary.blue}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "4rem",
              backgroundColor: "white",
              borderRadius: 5,
              width: "100%",
            }}
          >
            <Typography variant="p" sx={{ color: theme.palette.primary.blue }}>
              Battery state
            </Typography>
            <Typography variant="h2" sx={{ color: theme.palette.primary.blue }}>
              2 <span style={{ fontSize: "1rem" }}>kWh</span>
            </Typography>
          </Box>
        </div>
      </Box>
      <LineTo
        from="C"
        delay="1000"
        fromAnchor="top"
        borderWidth={2}
        borderColor={theme.palette.primary.red}
        to="A"
        toAnchor="bottom"
      />
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "180px",
          }}
        >
          <HouseIcon
            sx={{ fontSize: "6rem", color: theme.palette.primary.main }}
          />
        </Box>
        <Box
          sx={{
            width: "100px",
            height: "1.5px",
            background: "blue",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "180px",
          }}
        >
          <SolarPowerIcon
            sx={{ fontSize: "5rem", color: theme.palette.primary.green }}
          />
          <Typography variant="p" sx={{ color: theme.palette.primary.green }}>
            Production
          </Typography>
          <Typography variant="h1" sx={{ color: theme.palette.primary.green }}>
            10 <span style={{ fontSize: "1rem" }}>kWp</span>
          </Typography>
          <Box
            sx={{
              height: "100px",
              width: "1.5px",
              background: "transparent",
            }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        <Box
          sx={{
            height: "100px",
            width: "1.5px",
            backgroundColor: theme.palette.primary.red,
          }}
        ></Box>
        <Box
          sx={{
            height: "100px",
            width: "1.5px",
            backgroundColor: theme.palette.primary.red,
          }}
        ></Box>
        <Box
          sx={{
            height: "100px",
            width: "1.5px",
            backgroundColor: theme.palette.primary.red,
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "180px",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <GridIconEditable
              height="4.5rem"
              color={theme.palette.primary.red}
            />
          </Box>
          <Typography variant="p" sx={{ color: theme.palette.primary.red }}>
            Inflow
          </Typography>
          <Typography variant="h1" sx={{ color: theme.palette.primary.red }}>
            2 <span style={{ fontSize: "1rem" }}>kW</span>
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100px",
            height: "1.5px",
            background: "blue",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "180px",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <BatteryIconEditable
              height="4.5rem"
              width="80"
              color={theme.palette.primary.blue}
            />
          </Box>
          <Typography variant="p" sx={{ color: theme.palette.primary.blue }}>
            Inflow
          </Typography>
          <Typography variant="h1" sx={{ color: theme.palette.primary.blue }}>
            2 <span style={{ fontSize: "1rem" }}>kW</span>
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default ConnectorsGraph;
