import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import format from "date-fns/format";
import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";

const StyledButton = styled(Button)(({ theme, hidden, c }) => ({
  opacity: hidden ? 0.5 : 1,
  fontSize: "0.8rem",
  backgroundColor: c,
  ":hover": {
    backgroundColor: "transparent",
  },
}));
const COLORS = [
  "#FF5733",
  "#FFC300",
  "#4CAF50",
  "#03A9F4",
  "#9C27B0",
  "#FF9800",
  "#8BC34A",
  "#00BCD4",
  "#673AB7",
  "#E91E63",
  "#2196F3",
  "#F44336",
];
const renderLegend = (props) => {
  const { payload, onClick, handleAll } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        flexWrap: "wrap",
        gap: 3,
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "row", gap: 1, flexWrap: "wrap" }}
      >
        {payload.map((entry, i) => {
          return (
            <StyledButton
              key={i}
              variant="contained"
              c={entry.color}
              hidden={entry.payload.opacity !== 1}
              onClick={() => onClick(entry)}
            >
              {entry.value}
            </StyledButton>
          );
        })}
        <StyledButton variant="contained" c="#CCC" onClick={() => handleAll()}>
          Pokaż wszystkie
        </StyledButton>
      </Box>
    </Box>
  );
};
const CustomTooltip = ({ active, payload, label, theme, chartType }) => {
  if (active && payload && payload.length) {
    //
    //

    return (
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          border: "solid",
          borderColor: theme.palette.primary.background,
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">Time</Typography>
          <Typography variant="p">
            {format(new Date(label), "EEEE, HH:mm")}
          </Typography>
        </Box>
        <Box>
          {payload.map((entry, index) => {
            if (entry.opacity !== 0.2) {
              return (
                <Typography
                  key={index}
                  variant="h3"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "1rem",
                  }}
                >
                  <span style={{ fontWeight: "bold", color: entry.stroke }}>
                    {`${entry.name}:  ${
                      chartType === "current"
                        ? (entry.value * 2).toFixed(1)
                        : (entry.value / 14).toFixed(1)
                    } ${
                      chartType === "current"
                        ? "A"
                        : chartType === "power"
                        ? "W"
                        : "V"
                    }`}
                  </span>
                </Typography>
              );
            }
          })}
        </Box>
      </Box>
    );
  }

  return null;
};
const StringsChart = ({ chartType, series, handleHide, handleAll }) => {
  const theme = useTheme();
  console.log(series);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={series}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="x"
          type="category"
          tickFormatter={(tick) => format(new Date(tick), "HH:mm")}
          minTickGap={20}
          allowDuplicatedCategory={false}
          // label={{
          //   value: "Godzina",
          //   angle: 0,
          //   fontSize: "15",
          //   offset: "-15",
          //   position: "insideBottomLeft",
          // }}
        />
        <YAxis
          type="number"
          domain={["auto", "auto"]}
          label={{
            value: chartType === "current" ? "[A]" : "[V]",
            position: "insideTopLeft",
            offset: "-15",
          }}
          tickFormatter={(tick) =>
            chartType === "current"
              ? Math.round(tick * 2)
              : Math.round(tick / 14)
          }
        />
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={true}
          vertical={false}
        />
        <Tooltip
          content={<CustomTooltip theme={theme} chartType={chartType} />}
          cursor={{
            stroke: theme.palette.primary.background,
            strokeDasharray: 5,
          }}
        />
        {/* <Legend
          content={renderLegend}
          onClick={handleHide}
          handleAll={handleAll}
        /> */}
        {series.map((entry, index) => {
          return (
            <Line
              key={index}
              type="monotone"
              data={entry.data}
              dataKey="y"
              opacity={entry.hidden ? 0.2 : 1}
              strokeWidth={entry.hidden ? 0.5 : 2}
              name={`MPPT-${entry.string_id}`}
              stroke={COLORS[index]}
              activeDot={{ r: 8 }}
              dot={false}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
export default StringsChart;
