import { useEffect, useState } from "react";
import GetPowerData from "../api/GetPowerData";
import format from "date-fns/format";
import AreaChartBase from "./AreaChartBase";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import DayPicker from "./DayPicker";
const DailyPowerChart = () => {
  const theme = useTheme();
  const [powerData, setPowerData] = useState(null);
  const [pickedDate, setPickedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const handleChangeDate = (val) => {
    setPickedDate(val);
  };
  const handleFetch = async () => {
    setLoading(true);
    try {
      const response = await GetPowerData(format(pickedDate, "yyyy-MM-dd"), 4);
      if (response) {
        let temp = response.data.map((entry, i) => {
          return { ...entry, y: entry.y / 2 };
        });
        setPowerData(temp);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetch();
  }, [pickedDate]);
  return (
    <Box
      sx={{
        height: "25rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "normal" }}>
          Daily generation
        </Typography>
        <DayPicker handleChangeDate={handleChangeDate} />
      </Box>
      {loading && <LinearProgress />}
      {powerData && powerData.length > 0 && (
        <Box sx={{ height: "20rem" }}>
          <AreaChartBase color="pv" powerData={powerData} />
        </Box>
      )}
    </Box>
  );
};

export default DailyPowerChart;
