import {
  Area,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
} from "recharts";
import { useTheme } from "@emotion/react";
import { format } from "date-fns";
import { Box, Typography } from "@mui/material";

const CustomTooltip = ({ active, payload, label, theme }) => {
  if (active && payload && payload.length) {
    //
    //

    return (
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          border: "solid",
          borderColor: theme.palette.primary.background,
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">Time</Typography>
          <Typography variant="p">
            {format(new Date(label), "EEEE, HH:mm")}
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3">Active power</Typography>

          <Typography variant="p">
            {payload[0].value.toString().length > 3
              ? `${(payload[0].value / 100000).toFixed(2)} kW`
              : `${payload[0].value.toFixed(0)} kW`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

const AreaChartBase = ({ color, powerData }) => {
  const theme = useTheme();
  console.log(powerData);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={powerData}
        margin={{
          top: 30,
          right: 30,
          left: 30,
          bottom: 30,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="x"
          tickFormatter={(tick) => format(new Date(tick), "HH:mm")}
          minTickGap={20}
          label={{
            // value: "Godzina",
            angle: 0,
            fontSize: "15",
            offset: "-15",
            position: "insideBottomLeft",
          }}
        />
        <YAxis
          tickFormatter={(tick) => {
            return `${(tick / 100000).toFixed(2)}`;
          }}
          yAxisId="left"
          label={{
            angle: 0,
            value: `kW`,
            // fontSize: "15",
            offset: "10",
            position: "top",
          }}
        />

        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={true}
          vertical={false}
        />
        <Tooltip
          content={<CustomTooltip theme={theme} />}
          cursor={{
            stroke: theme.palette.primary.background,
            strokeDasharray: 5,
          }}
        />
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="50%"
              stopColor={
                color === "pv"
                  ? theme.palette.primary.green
                  : theme.palette.primary.main
              }
              stopOpacity={0.35}
            />
            <stop
              offset="100%"
              stopColor={
                color === "pv"
                  ? theme.palette.primary.green
                  : theme.palette.primary.main
              }
              stopOpacity={1}
            />
          </linearGradient>
        </defs>
        <Area
          yAxisId="left"
          type="monotone"
          // name="Moc"
          dataKey="y"
          stroke={
            color === "pv"
              ? theme.palette.primary.green
              : theme.palette.primary.main
          }
          strokeWidth={2}
          fill="url(#splitColor)"
        />

        {/* <Legend /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default AreaChartBase;
