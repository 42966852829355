import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import GetInvertersTable from "../api/GetInvertersTable";
import { createContext, useEffect, useState } from "react";
import DevicesList from "../components/DevicesList";
import img from "../utils/inv-mockup.png";
import img2 from "../utils/inv-2mockup.png";
import { useTheme } from "@emotion/react";
import PhaseChartWrapper from "../components/PhaseChartWrapper";
import StringWrapper from "../components/StringWrapper";
export const InvertersContext = createContext();
const Analysis = () => {
  const theme = useTheme();
  const [inverters, setInverters] = useState(null);
  const [pickedInv, setPickedInv] = useState(null);
  const handleChangePickedInv = (val) => {
    setPickedInv(val);
  };
  const handleGetInverters = async () => {
    try {
      let response = await GetInvertersTable(4);
      setInverters(response.data);
      handleChangePickedInv(response.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleGetInverters();
  }, []);
  return (
    <InvertersContext.Provider
      value={{ inverters, handleChangePickedInv, pickedInv }}
    >
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          spacing={2}
          padding={3}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          <Grid item md={12} lg={12}>
            <Paper
              sx={{
                // width: "100%",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                height: "6rem",
              }}
            >
              <DevicesList />
            </Paper>
          </Grid>
          <Grid item md={12} lg={4}>
            {" "}
            <Paper
              sx={{
                // width: "100%",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                height: "15rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src={pickedInv?.slave_id === 1 ? img : img2}
                  height="200"
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography variant="h2">
                    {pickedInv?.slave_id === 1 ? "TSOL-MP3000" : "TSOL-MP2250"}
                  </Typography>
                  <Divider
                    flexItem
                    sx={{ backgroundColor: theme.palette.primary.main }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="p" sx={{ fontWeight: "bold" }}>
                      {pickedInv?.slave_id === 1 ? 6 : 4}
                    </Typography>
                    <Typography variant="p">Quantity of MPPT</Typography>
                  </Box>
                  <Divider
                    flexItem
                    sx={{ backgroundColor: theme.palette.primary.main }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="p" sx={{ fontWeight: "bold" }}>
                      {pickedInv?.slave_id === 1 ? "3000 W" : "2250 W"}
                    </Typography>
                    <Typography variant="p">Capacity</Typography>
                  </Box>
                  <Divider
                    flexItem
                    sx={{ backgroundColor: theme.palette.primary.main }}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={12} lg={8}>
            {" "}
            <Paper
              sx={{
                // width: "100%",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                height: "15rem",
              }}
            >
              {pickedInv && <PhaseChartWrapper pickedInv={pickedInv} />}
            </Paper>
          </Grid>
          <Grid item md={12} lg={6}>
            {" "}
            <Paper
              sx={{
                // width: "100%",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                height: "20rem",
              }}
            >
              {pickedInv && (
                <StringWrapper
                  pickedInv={pickedInv}
                  chartType={"voltage"}
                  mpptAmount={pickedInv?.slave_id === 1 ? 6 : 4}
                />
              )}
            </Paper>
          </Grid>
          <Grid item md={12} lg={6}>
            {" "}
            <Paper
              sx={{
                // width: "100%",
                borderRadius: "10px",
                backgroundColor: "white",
                boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                paddingTop: "1rem",
                paddingBottom: "1rem",
                height: "20rem",
              }}
            >
              {" "}
              {pickedInv && (
                <StringWrapper
                  pickedInv={pickedInv}
                  chartType={"current"}
                  mpptAmount={pickedInv?.slave_id === 1 ? 6 : 4}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </InvertersContext.Provider>
  );
};

export default Analysis;
