import GetPhaseDatas from "../api/GetPhaseDatas";
import { useEffect, useState } from "react";
import PhaseChart from "./PhaseChart";
import { Box, LinearProgress, Typography } from "@mui/material";
import DayPicker from "./DayPicker";
const PhaseChartWrapper = ({ pickedInv }) => {
  const [pickedDate, setPickedDate] = useState(new Date());
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);
  const handleChangeDate = (val) => {
    setPickedDate(val);
  };
  const handleGetPhaseData = async () => {
    setLoading(true);
    try {
      let response = await GetPhaseDatas(pickedInv.id, pickedDate);

      setData(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetPhaseData();
  }, [pickedInv, pickedDate]);
  return (
    <Box
      sx={{
        height: "14rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" sx={{ fontWeight: "normal" }}>
          Phase voltage
        </Typography>
        {loading && <LinearProgress />}
        <DayPicker handleChangeDate={handleChangeDate} />
      </Box>
      {data && !loading && <PhaseChart data={data} />}
    </Box>
  );
};

export default PhaseChartWrapper;
