import { Box, Button, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import { InvertersContext } from "../pages/Analysis";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useTheme } from "@emotion/react";
const DevicesList = () => {
  const { inverters, handleChangePickedInv, pickedInv } =
    useContext(InvertersContext);
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
      {inverters?.map((entry, index) => {
        return (
          <React.Fragment key={`${entry.unit_name}-${index}`}>
            <Box
              sx={{
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <FiberManualRecordIcon
                  sx={{ color: theme.palette.primary.green }}
                />{" "}
                <Typography variant="h3">
                  {index === 0 ? "TSOL-MP3000" : "TSOL-MP2250"}
                </Typography>
              </Box>
              <Typography variant="p">
                Status:{" "}
                <span style={{ color: theme.palette.primary.green }}>
                  Working
                </span>
              </Typography>
              <Typography variant="p">
                Capacity: {index === 0 ? "3000 W" : "2250 W"}
              </Typography>
              <Button
                size="small"
                variant="contained"
                disabled={entry === pickedInv}
                onClick={() => handleChangePickedInv(entry)}
              >
                Details
              </Button>
            </Box>
            <Divider flexItem orientation="vertical" />
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default DevicesList;
