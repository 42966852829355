import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F15A09",
      mainTransparent: "rgba(241,90,9,0.15)",
      background: "#E0E4E7",
      green: "#31B784",
      red: "#DD525D",
      blue: "#40B6CE",
    },
    text: {
      primary: "rgba(0,0,0,0.9)",
      secondary: "rgba(255,255,255,0.9)",
      primaryTransparent: "rgba(0,0,0,0.5)",
      secondaryTransparent: "rgba(255,255,255,0.6)",
    },
  },
});

theme.typography.h1 = {
  color: "rgba(0, 0, 0, 0.9)",
  fontSize: "2rem",
  fontFamily: ["Poppins, sans-serif"],
};
theme.typography.h2 = {
  color: "rgba(0, 0, 0, 0.9)",
  fontSize: "1.5rem",
  fontWeight: "bold",
  fontFamily: ["Poppins, sans-serif"],
};
theme.typography.h3 = {
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "1rem",
  fontFamily: ["Montserrat, sans-serif"],
};
theme.typography.h4 = {
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "1rem",
  fontWeight: "normal",
  fontFamily: ["Poppins, sans-serif"],
};
theme.typography.p = {
  color: "rgba(0, 0, 0, 0.9)",
  fontSize: "1rem",
  // fontWeight: "regular",
  fontFamily: ["Poppins, sans-serif"],
};
