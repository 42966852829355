import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ToggleAggr = ({ handleChangeAggr, aggr }) => {
  return (
    <ToggleButtonGroup
      orientation="horizontal"
      value={aggr}
      exclusive
      onChange={handleChangeAggr}
    >
      <ToggleButton value="day" aria-label="list">
        Daily
      </ToggleButton>
      <ToggleButton value="month" aria-label="module">
        Monthly
      </ToggleButton>
      <ToggleButton value="year" aria-label="quilt">
        Yearly
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
export default ToggleAggr;
