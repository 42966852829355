import { List, ListItem, Divider, Drawer, Box, Button } from "@mui/material";
import logo from "../utils/logo-color.png";
import { useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";
const Navbar = () => {
  const drawerWidth = 260;
  const theme = useTheme();
  const { logout } = useContext(AuthContext);
  return (
    <Drawer
      sx={{
        width: drawerWidth,

        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box sx={{ padding: "1rem" }}>
        <img src={logo} width="100px" />
      </Box>
      <Divider />
      <List>
        <ListItem>
          <NavLink
            to="/"
            style={({ isActive, isPending }) => {
              return {
                textDecoration: "none",
                fontFamily: ["Poppins, sans-serif"],
                borderRadius: "5px",

                width: "100%",
                padding: "0.5rem",
                fontWeight: isActive ? "bold" : "normal",
                backgroundColor:
                  isActive && theme.palette.primary.mainTransparent,
                color: isActive
                  ? theme.palette.text.primary
                  : theme.palette.text.primaryTransparent,
              };
            }}
          >
            {({ isActive, isPending }) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
                <DashboardIcon
                  sx={{ color: isActive && theme.palette.primary.main }}
                />{" "}
                Overview
              </Box>
            )}
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink
            to="/analysis"
            style={({ isActive, isPending }) => {
              return {
                textDecoration: "none",
                fontFamily: ["Poppins, sans-serif"],
                borderRadius: "5px",
                fontWeight: isActive ? "bold" : "normal",
                width: "100%",
                padding: "0.5rem",
                backgroundColor:
                  isActive && theme.palette.primary.mainTransparent,
                color: isActive
                  ? theme.palette.text.primary
                  : theme.palette.text.primaryTransparent,
              };
            }}
          >
            {({ isActive, isPending }) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
                <BarChartIcon
                  sx={{ color: isActive && theme.palette.primary.main }}
                />{" "}
                Analysis
              </Box>
            )}
          </NavLink>
        </ListItem>
      </List>
      <Divider />

      <Button startIcon={<LogoutIcon />} onClick={logout}>
        Logout
      </Button>
    </Drawer>
  );
};

export default Navbar;
