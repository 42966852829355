import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./providers/AuthProvider";
import Login from "./pages/Login";
import Wrapper from "./pages/Wrapper";
import Overview from "./pages/Overview";

import Analysis from "./pages/Analysis";
import Report from "./pages/Report";
const App = () => {
  const { restore, state } = useContext(AuthContext);
  const [isTokenRestored, setIsTokenRestored] = useState(false);
  useEffect(() => {
    restore();
    setIsTokenRestored(true);
  }, []);

  return (
    <>
      {isTokenRestored && (
        <Routes>
          <Route
            path="login"
            element={state.token === null ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={state.token === null ? <Login /> : <Wrapper />}
          >
            <Route
              index
              element={state.token === null ? <Login /> : <Overview />}
            />
            <Route
              path="/analysis"
              element={state.token === null ? <Login /> : <Analysis />}
            />
            <Route
              path="/reports"
              element={state.token === null ? <Login /> : <Report />}
            />
          </Route>
          <Route path="*" element={<Navigate replace to="/login" />}></Route>
        </Routes>
      )}
    </>
  );
};

export default App;
