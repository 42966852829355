import { DatePicker } from "@mui/x-date-pickers";
import { Box } from "@mui/material";
import { useState } from "react";
const BrowserInput = function BrowserInput(props) {
  const { inputProps, InputProps, ownerState, inputRef, error, ...other } =
    props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} ref={InputProps?.ref}>
      <input ref={inputRef} {...inputProps} {...other} />
      {InputProps?.endAdornment}
    </Box>
  );
};
const DayPicker = ({ handleChangeDate }) => {
  const [value, setValue] = useState(new Date());
  return (
    <DatePicker
      label="Data"
      inputFormat="yyyy-MM-dd"
      value={value}
      disableFuture={true}
      onChange={(newValue) => {
        handleChangeDate(newValue);
        setValue(newValue);
      }}
      slots={{
        textField: BrowserInput,
      }}
    />
  );
};
export default DayPicker;
