import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@emotion/react";
import { Box, Typography, Divider } from "@mui/material";
import format from "date-fns/format";
const CustomTooltip = ({ active, payload, label, theme, aggr }) => {
  if (active && payload && payload.length) {
    //
    //

    return (
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          border: "solid",
          borderColor: theme.palette.primary.background,
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">
            {aggr === "day" ? "Time" : aggr === "month" ? "Day" : "Month"}
          </Typography>
          <Typography variant="p">
            {aggr === "day"
              ? format(new Date(label), "EEEE, HH:mm")
              : aggr === "month"
              ? format(new Date(label), "EEEE, dd/MM")
              : format(new Date(label), "MM/yyyy")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3">Energy yield</Typography>

          <Typography variant="p">
            {payload[0].value.toString().length > 3
              ? `${(payload[0].value / 10000).toFixed(2)} MWh`
              : `${(payload[0].value / 10).toFixed(2)} kWh`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};
const CustomTooltipMulti = ({ active, payload, label, theme, aggr }) => {
  if (active && payload && payload.length) {
    //
    //

    return (
      <Box
        sx={{
          backgroundColor: "#FCFCFC",
          borderRadius: "15px",
          padding: "2rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          border: "solid",
          borderColor: theme.palette.primary.background,
        }}
      >
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography variant="h3">
            {aggr === "day" ? "Time" : aggr === "month" ? "Day" : "Month"}
          </Typography>
          <Typography variant="p">
            {aggr === "day"
              ? format(new Date(label), "EEEE, HH:mm")
              : aggr === "month"
              ? format(new Date(label), "EEEE, dd/MM")
              : format(new Date(label), "MM/yyyy")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h3" sx={{ maxWidth: "8rem", textWrap: "wrap" }}>
            Zapotrzebowanie na energię
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {payload.map((entry, index) => {
              return (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Typography variant="p" sx={{ color: entry.fill }}>
                      {entry.name}
                    </Typography>
                    <Typography variant="p">{entry.value} kWh</Typography>
                  </Box>
                  <Divider />
                </>
              );
            })}
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="p">Suma</Typography>
                <Typography variant="p">
                  {payload[0].value + payload[1].value} kWh
                </Typography>
              </Box>
              <Divider />
            </>
          </Box>
        </Box>
      </Box>
    );
  }

  return null;
};
const BarChartBase = ({ color, aggr, energyData, type }) => {
  console.log(type === "balance");
  console.log(type);
  console.log(energyData);
  const theme = useTheme();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={energyData}
        margin={{
          top: 30,
          right: 30,
          left: 30,
          bottom: 30,
        }}
      >
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="50%"
              stopColor={
                color === "pv"
                  ? theme.palette.primary.green
                  : theme.palette.primary.main
              }
              stopOpacity={1}
            />
            <stop
              offset="100%"
              stopColor={
                color === "pv"
                  ? theme.palette.primary.green
                  : theme.palette.primary.main
              }
              stopOpacity={0.05}
            />
          </linearGradient>
        </defs>

        <XAxis
          dataKey="x"
          tickFormatter={(tick) => {
            if (aggr === "day") {
              return format(tick, "HH:mm");
            }
            if (aggr === "month") {
              return format(tick, "dd");
            }
            if (aggr === "year") {
              return format(tick, "MMM.");
            }
          }}
          minTickGap={20}
          label={{
            angle: 0,
            fontSize: "15",
            offset: "-15",
            position: "insideBottomLeft",
          }}
        />
        <YAxis
          tickFormatter={(tick) => {
            return `${(tick / 10).toFixed(2)}`;
          }}
          label={{
            angle: 0,
            value: `kWh`,
            // fontSize: "15",
            offset: "10",
            position: "top",
          }}
        />
        <CartesianGrid
          strokeDasharray="3 3"
          horizontal={true}
          vertical={false}
        />
        <Tooltip
          content={
            type === "balance" ? (
              <CustomTooltipMulti theme={theme} aggr={aggr} />
            ) : (
              <CustomTooltip theme={theme} aggr={aggr} />
            )
          }
        />
        {type === "single" && (
          <Bar
            dataKey="y"
            radius={[50, 50, 0, 0]}
            fill={theme.palette.primary.main}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};
export default BarChartBase;
