import { api } from "../providers/AuthProvider";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
const GetPVEnergy = async (id, date, aggr) => {
  console.log(aggr);
  return api
    .get(`/api/pv_energy_data/${id}`, {
      params: {
        date:
          aggr === "day"
            ? format(date, "yyyy-MM-dd")
            : aggr === "month"
            ? format(startOfMonth(date), "yyyy-MM-dd")
            : aggr === "year" && format(startOfYear(date), "yyyy-MM-dd"),
        date_aggr: aggr,
      },
    })

    .then((res) => {
      return res;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default GetPVEnergy;
