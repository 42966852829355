const BatteryIconEditable = ({ color, height, width }) => {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 37 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 1H4.5C2.567 1 1 2.98714 1 5.43841V23.192C1 25.6433 2.567 27.6304 4.5 27.6304H29C30.933 27.6304 32.5 25.6433 32.5 23.192V5.43841C32.5 2.98714 30.933 1 29 1Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 9.87681H35.125C35.3571 9.87681 35.5796 9.99371 35.7437 10.2018C35.9078 10.4099 36 10.6921 36 10.9864V17.644C36 17.9383 35.9078 18.2205 35.7437 18.4286C35.5796 18.6367 35.3571 18.7536 35.125 18.7536H32.5V9.87681Z"
        stroke="#40B6CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.70117 6.41367C4.70117 5.84434 5.1627 5.38281 5.73203 5.38281C6.30135 5.38281 6.76288 5.84434 6.76288 6.41367V22.2201C6.76288 22.7894 6.30135 23.251 5.73203 23.251C5.1627 23.251 4.70117 22.7894 4.70117 22.2201V6.41367Z"
        fill="#40B6CE"
      />
      <path
        d="M9.05367 6.41367C9.05367 5.84434 9.5152 5.38281 10.0845 5.38281C10.6539 5.38281 11.1154 5.84434 11.1154 6.41367V22.2201C11.1154 22.7894 10.6539 23.251 10.0845 23.251C9.5152 23.251 9.05367 22.7894 9.05367 22.2201V6.41367Z"
        fill="#40B6CE"
      />
      <path
        d="M13.4062 6.41367C13.4062 5.84434 13.8677 5.38281 14.437 5.38281C15.0064 5.38281 15.4679 5.84434 15.4679 6.41367V22.2201C15.4679 22.7894 15.0064 23.251 14.437 23.251C13.8677 23.251 13.4062 22.7894 13.4062 22.2201V6.41367Z"
        fill="#40B6CE"
      />
      <path
        d="M17.7587 6.41367C17.7587 5.84434 18.2202 5.38281 18.7895 5.38281C19.3589 5.38281 19.8204 5.84434 19.8204 6.41367V22.2201C19.8204 22.7894 19.3589 23.251 18.7895 23.251C18.2202 23.251 17.7587 22.7894 17.7587 22.2201V6.41367Z"
        fill="#40B6CE"
      />
      <path
        d="M22.1112 6.41367C22.1112 5.84434 22.5727 5.38281 23.142 5.38281C23.7114 5.38281 24.1729 5.84434 24.1729 6.41367V22.2201C24.1729 22.7894 23.7114 23.251 23.142 23.251C22.5727 23.251 22.1112 22.7894 22.1112 22.2201V6.41367Z"
        fill="#40B6CE"
      />
      <path
        d="M26.4637 6.41367C26.4637 5.84434 26.9252 5.38281 27.4945 5.38281C28.0639 5.38281 28.5254 5.84434 28.5254 6.41367V22.2201C28.5254 22.7894 28.0639 23.251 27.4945 23.251C26.9252 23.251 26.4637 22.7894 26.4637 22.2201V6.41367Z"
        fill="#40B6CE"
      />
    </svg>
  );
};

export default BatteryIconEditable;
