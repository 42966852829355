import { useTheme } from "@emotion/react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import ConnectorsGraph from "./ConnectorsGraph";
import DailyPowerChart from "../components/DailyPowerChart";
import EnergyChartWrapper from "../components/EnergyChartWrapper";
import mockup from "../utils/solar-panel-mockup.png";
const Overview = () => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={2}
        padding={3}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item md={12} lg={8}>
          <Paper
            sx={{
              // width: "100%",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
              padding: "2rem",
              height: "24rem",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Typography
                variant="h2"
                sx={{ color: theme.palette.text.primary, fontWeight: "normal" }}
              >
                Performance monitoring
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingLeft: "3rem",
                  paddingRight: "3rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    width: "30rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primaryTransparent }}
                      >
                        Produced today
                      </Typography>
                      <Typography
                        variant="h1"
                        sx={{
                          color: theme.palette.text.primary,
                          fontSize: "3rem",
                        }}
                      >
                        8.03<span style={{ fontSize: "2rem" }}>kWh</span>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "10rem",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primaryTransparent }}
                      >
                        Power today
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        Min{" "}
                        <span
                          style={{
                            fontSize: "1rem",
                            color: theme.palette.primary.red,
                          }}
                        >
                          500 W
                        </span>
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        Max{" "}
                        <span
                          style={{
                            fontSize: "1rem",
                            color: theme.palette.primary.green,
                          }}
                        >
                          5130 W
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primaryTransparent }}
                      >
                        Total energy usage today
                      </Typography>
                      <Typography
                        variant="h1"
                        sx={{
                          color: theme.palette.text.primary,
                          fontSize: "3rem",
                        }}
                      >
                        16.89<span style={{ fontSize: "2rem" }}>kWh</span>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "10rem",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primaryTransparent }}
                      >
                        Balance
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        Grid{" "}
                        <span
                          style={{
                            fontSize: "1rem",
                            color: theme.palette.primary.red,
                          }}
                        >
                          50.8%
                        </span>
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{ color: theme.palette.text.primary }}
                      >
                        PV{" "}
                        <span
                          style={{
                            fontSize: "1rem",
                            color: theme.palette.primary.green,
                          }}
                        >
                          49.2%
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ backgroundColor: "rgba(255,255,255,0.6)" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  <Box>
                    <img src={mockup} style={{ maxWidth: 200 }} />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="p"
                      sx={{ color: theme.palette.text.primaryTransparent }}
                    >
                      Capacity
                    </Typography>
                    <Typography
                      variant="h1"
                      sx={{ color: theme.palette.text.primary }}
                    >
                      5250 <span style={{ fontSize: "1rem" }}>W</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item md={12} lg={4}>
          <Paper
            sx={{
              // width: "100%",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
              height: "24rem",
              padding: "2rem",
            }}
          >
            <ConnectorsGraph />
          </Paper>
        </Grid>
        <Grid item md={12} lg={8}>
          <Paper
            sx={{
              // width: "100%",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
              height: "22rem",
              padding: "2rem",
            }}
          >
            <DailyPowerChart />
          </Paper>
        </Grid>
        <Grid item md={12} lg={4}>
          <Paper
            sx={{
              // width: "100%",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 8px 24px -17px rgba(66, 68, 90, 1)",
              padding: "2rem",
              height: "22rem",
            }}
          >
            <EnergyChartWrapper />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;
