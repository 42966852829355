const GridIconEditable = ({ color, height }) => {
  return (
    <svg
      // width='100px'
      height={height}
      viewBox="0 0 79 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9 17.75L12 13.25L18.3 0.5H60.65L67 13.25L58.1 17.7L54.5 10.5H24.5L20.9 17.75ZM72.5 30.5H50L46 15.5H33L29.05 30.5H6.4L0 43.25L8.95 47.7L12.5 40.5H66.4L70 47.75L78.95 43.3L72.5 30.5ZM68.35 100.5H58L56.8 96L39.5 70L22.15 96L21 100.5H10.65L25 45.5H35.45L33.65 52.25L39.5 61L45.3 52.25L43.55 45.5H53.9L68.35 100.5ZM36.5 65.5L32 58.75L26.1 81.15L36.5 65.5ZM52.9 81.1L47 58.7L42.5 65.5L52.9 81.1Z"
        fill={color}
      />
    </svg>
  );
};

export default GridIconEditable;
